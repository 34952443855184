import React from "react"
import { Link } from "gatsby"

import "./PropertiesList.scss"

const PropertiesList = ({ data }) => (
  <ul className="properties__list">
    {data.map((item, index) => (
      <li key={index}>
        <Link to={item.path}>{item.pageContext.property}</Link>
      </li>
    ))}
  </ul>
)

export default PropertiesList
