import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo/Seo"
import PageHeader from "../components/PageHeader/PageHeader"
import Footer from "../components/Footer/Footer"
import Container from "../components/Container/Container"
import Box from "../components/Box/Box"
import PropertiesList from "../components/PropertiesList/PropertiesList"

const Properties = ({ data, location }) => {
  console.log(data)
  const letters = [
    ...new Set(
      data.allSitePage.nodes.map(item => item.pageContext.property[0])
    ),
  ]
  return (
    <>
      <Seo
        title={`Właściwości CSS | CSSGENERATOR.PL`}
        description="Właściwości CSS | Wygodne narzędzie, dzięki któremu wygenerujesz gotowe reguły CSS. Dostosuj wartości i skopiuj gotowy kod."
        location={location}
      />
      <PageHeader
        h1="Właściwości CSS"
        desc="Poniżej znajdziesz listę zawierającą prawie wszystkie właściwości CSS. Służą one do zmiany wyglądu elementów HTML."
        notGenerator
      />

      <Container>
        <div className="description">
          <Box>
            <h2>Czym są właściwości CSS?</h2>
            <p>
              Właściwości CSS to atrybuty, które opisują wygląd i formatowanie
              elementów HTML na stronie internetowej. Dzięki{" "}
              <a href="/blog/co-to-jest-css/">CSS</a>, można zdefiniować wiele
              różnych właściwości, takich jak kolor tekstu, tło, odstępy między
              elementami, wielkość tekstu, itp. Właściwości te są ustawiane w
              regułach CSS i odnoszą się do selektora, którym są określane
              elementy HTML.
            </p>
            <h2>Jakie są najpopularniejsze właściwości CSS?</h2>
            <p>
              Niektóre z najczęściej używanych właściwości CSS to: "
              <a href="/wlasciwosci-css/color/">color</a>" do ustawiania koloru
              tekstu, "
              <a href="/wlasciwosci-css/background-color/">background-color</a>"
              do ustawiania koloru tła, "
              <a href="/wlasciwosci-css/font-size/">font-size</a>" do ustawiania
              wielkości tekstu, "<a href="/wlasciwosci-css/margin/">margin</a>"
              do ustawiania marginesu wokół elementu, "
              <a href="/wlasciwosci-css/padding/">padding</a>" do ustawiania
              odstępu wewnętrznego, "<a href="/wlasciwosci-css/width/">width</a>
              " i "<a href="/wlasciwosci-css/height/">height</a>" do ustawiania
              szerokości i wysokości elementu, "
              <a href="/wlasciwosci-css/border/">border</a>" do dodawania
              obramowania, "
              <a href="/wlasciwosci-css/text-align/">text-align</a>" do
              wyrównywania tekstu, "
              <a href="/wlasciwosci-css/display/">display</a>" do określania jak
              element jest wyświetlany na stronie, i wiele innych. Dzięki tak
              szerokiej gamie właściwości, można precyzyjnie kontrolować wygląd
              i formatowanie swojej strony internetowej. Właściwości CSS są
              bardzo elastyczne i pozwalają na tworzenie różnorodnych stylów, od
              prostych i czystych po bardzo zaawansowane i unikalne.
            </p>
          </Box>
        </div>

        <Box>
          {letters.map(letter => (
            <div key={letter} className="properties__wrapper">
              <h2>{letter.toUpperCase()}</h2>
              <PropertiesList
                data={data.allSitePage.nodes.filter(
                  item => item.pageContext.property[0] === letter
                )}
              />
            </div>
          ))}
        </Box>
      </Container>

      <Footer />
    </>
  )
}

export default Properties

export const query = graphql`
  query {
    allSitePage(
      filter: { component: { regex: "/css-properties-template.js/" } }
    ) {
      nodes {
        path
        pageContext
      }
    }
  }
`
